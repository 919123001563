<template>
  <div class="activity_home" v-if="hd.id">

    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in hd.banner" :key="index">
        <img v-lazy="image" width="100%"/>
      </van-swipe-item>
    </van-swipe>
    <div class="countDown">
      <van-count-down :time="time">
        <template #default="timeData">
          <div class="text" v-if="hd.state==2">活动已结束</div>
          <label v-else>
            <span>距{{hd.state==0?'开始':'结束'}}：</span>
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">时</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">分</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">秒</span>
          </label>
        </template>
      </van-count-down>
    </div>
    
    <div class="container">

      <div class="cardBox">
        <van-row type="flex">
          <van-col span="8" v-for="item in 9" :key="item" :class="['item',(element_index==item && !config.flag)?'act':'']">
            <a @click="onClickCard(item)">
              <img :src="gList[config.key].img" v-if="element_index==item && !config.flag && config.key != undefined">
              <figure></figure>
            </a>
          </van-col>
        </van-row>
      </div>
  
      <div class="cjnum">
        <span v-if="config.cj_num>0">您还有 {{config.cj_num}} 次抽奖机会！</span>
        <span v-else>您的抽奖次数已用完</span>
      </div>
      
      <van-row class="user_statistics">
        <van-col :span="goodList.length?6:8" class="item">
          <label>{{hd.total_user}}</label>
          <span>已参与</span>
        </van-col>
        <van-col :span="goodList.length?6:8" class="item">
          <label>{{hd.tj_num}}</label>
          <span>已中奖</span>
        </van-col>
        <van-col span="6" class="item" v-if="goodList.length>0">
          <label>{{hd.seckill_tj_num}}</label>
          <span>已购买</span>
        </van-col>
        <van-col :span="goodList.length?6:8" class="item">
          <label>{{hd.amount}}</label>
          <span>已阅读</span>
        </van-col>
      </van-row>
      
      <div class="index_box mt10" v-if="hd.total_user>0">
        <div class="h4">已有 <em>{{hd.total_user}}</em> 人参与</div>
        <userList :hdid="hd.id"/>
      </div>
      
      <template v-if="hd.is_showgood">
        <div class="column">
          <div class="h4">奖品区</div>
        </div>
        
        <goodList :type="1" :dataList="gList" :data="hd" :config="{action:false,is_stock:false,now_price:true,old_price:false,is_prize:true}" v-if="gList.length"/>
      
      </template>
  
      <template v-if="goodList.length>0">
        <div class="column mt10">
          <div class="h4">秒杀 ● 抢购区</div>
        </div>
        <goodList :type="2" :dataList="goodList" :data="hd" v-if="goodList.length"/>
      </template>
      
      <div class="index_box" v-if="hd.seckill_tj_num>0">
        <div class="h4">已有 <em>{{hd.seckill_tj_num}}</em> 人购买</div>
        <orderuser :hdid="hd.id" :order_type="1"/>
      </div>
      
      <template v-if="hd.content != null && hd.content != ''">
        <div class="column">
          <div class="h4">活动介绍</div>
        </div>
        <div class="textContent index_box" v-html="hd.content"></div>
      </template>
      
      <div class="mt10"><shopInfo :data="hd.shopInfo"/></div>
    </div>
  </div>
</template>

<script>
  import shopInfo from '@/pages/wx/components/shopInfo'
  import goodList from '@/pages/wx/components/goodList'
  import userList from '@/pages/wx/components/userList'
  import orderuser from '@/pages/wx/components/orderuserList'
  export default {
    props: {
      hd:Object,
      config:Object,
      goodList:Array,
      gList:Array
    },
    components: {goodList,userList,orderuser,shopInfo},
    data() {
      return {
        time:0,
        element_index:undefined
      }
    },
    activated() {
      this.setBgColor()
    },
    created(){
      this.setBgColor()
    },
    mounted() {
      this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();
    },
    methods: {
      setBgColor(){
        if(this.hd.bgcolor==null || this.hd.bgcolor==''){
          document.querySelector('body').setAttribute('style', 'background-color:#cfdfa8')
        }
      },
      onClickCard(record){
        this.element_index = record
        this.$emit('onPoint',{type:5})
      }
    }
  }
</script>
<style lang="less">
  @import "./css/template1.less";
</style>
